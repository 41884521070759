import React, { useState } from "react"
import { navigate } from "@reach/router"
import useAuth from "../../util/useStrapiAuth"
import { useForm } from "react-hook-form"
import { passRedirectThroughAuth } from "../../util/authRedirect"

const ConfirmEmail = ({ location }) => {
  const { confirmEmail } = useAuth()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [serverState, setServerState] = useState({
    submitting: false,
    submitError: null,
    status: null,
  })

  const processConfirm = (data, e) => {
    e.preventDefault()
    setServerState({ submitting: true })
    confirmEmail(data.code)
      .then(() => {
        setServerState({ submitting: false })
        navigate("/emailconfirmed", {
          state: {
            redirect: passRedirectThroughAuth(location, "/emailconfirmed"),
          },
        })
      })
      .catch(e => {
        setServerState({ submitting: false })
        const msg = e.response?.data?.message?.[0].messages?.[0].message
        setServerState({
          submitError:
            msg ??
            "An error has occurred. Please check your internet connection and try again.",
        })
      })
  }

  return (
    <div className="contact">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3>
              Successfully registered! Please provide the confirmation code we
              have sent to your email address:
            </h3>
            <div className="contact-form">
              <div className="form">
                <div className="form-group col-md-6">
                  <form onSubmit={handleSubmit(processConfirm)}>
                    <div className="form-group">
                      <input
                        {...register("code", {
                          required: "This field is required.",
                        })}
                        className="form-control"
                        type="text"
                        placeholder="Confirmation code"
                      />
                      <span className="formFieldError">
                        {errors?.code && errors.code.message}
                      </span>
                    </div>
                    <div className="btndiv">
                      <button
                        type="submit"
                        disabled={serverState.submitting}
                        className="btn"
                      >
                        {serverState.submitting ? "confirming.." : "Confirm"}
                      </button>
                    </div>
                    {serverState.submitError?.length > 1 && (
                      <p className="formSubmitError">
                        {serverState.submitError}
                      </p>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmEmail
