import React from "react"
import ConfirmRegistration from "../components/auth/confirmemail"
import PageHeader from "../components/pageheader"
import PageBody from "../components/pagebody"
import Layout from "../components/layout"

const ConfirmEmailPage = ({ location }) => {
  return (
    <Layout currPage="confirmemail" location={location}>
      <PageHeader pageTitle="Confirm email" />
      <PageBody>
        <ConfirmRegistration location={location} />
      </PageBody>
    </Layout>
  )
}

export default ConfirmEmailPage
